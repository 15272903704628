<template>
  <b-modal hide-footer v-model="showModal" title="About MyIsa" @hidden="close">
    <img class="logo" :src="IMGService.logoMintt" alt="" style="width: 200px"/>
    <br/>
    <br/>
    <br/>
    <span > MyISA version {{MYISAVERSION}} / {{ VERSIONDATE }} </span>
    <br/>
    <br/>
    <b-button v-if="$store.state.user.kioskable" @click="$store.commit('user/SET_KIOSKABLE', false);">{{ $t('Deactivate Kioskable')}}</b-button>
    <b-button v-if="!$store.state.user.kioskable" @click="$store.commit('user/SET_KIOSKABLE', true);">{{ $t('Activate Kioskable')}}</b-button>
    <br/>
    <b-button @click="upgradeMyIsa">{{ $t('Upgrade version')}}</b-button>    
    <b-button @click="clearDataCache">{{ $t('Clear data cache')}}</b-button>
    <b-button @click="clearUserCache">{{ $t('Clear credential cache')}}</b-button>
    

       
  </b-modal>
</template>

<script>
import { VERSIONDATE, MYISAVERSION } from '@/version'
import { IMGService } from '@/services/img.service'
import { SPService } from '@/services/sp.service.js'
import { API_URL, switchAPI } from '../../../.env';

const storageKeyU = 'ISAWEBAPPU';
const storageKeyF = 'ISAWEBAPPF';

export default {
  components: {    
  },
  data() {
    return {
      showModal: false,
      VERSIONDATE,
      MYISAVERSION,
      IMGService
    };
  },
  watch: {
    "$store.state.user.showAbout": function (val) {
      this.showModal = val;

      
    },    
    showLoginModal: function (val) {
      if (!val) {
        this.$store.dispatch("user/showAbout", false);        
      }
    },
  },
  methods: {
     close() {           
          this.$store.dispatch("user/showAbout", false);                 
      },
    async upgradeMyIsa() {
      const data = {
            action: 'reload',
            data: VERSIONDATE,
        }
        try {
            await SPService.wbevent(API_URL.url, this.$store.state.user.currentUser.jwt, data);
        } catch (err) {

        }
        debugger; window.location.reload();
    },
    async clearDataCache() {

      this.$dialog
          .confirm(this.$t('This will purge all known users on this device and will force you to login again all users. There are no ways to go back. If you are not sure of what you are doing, please do not do it.'), { okText: this.$t('Purge'), cancelText: this.$t('Cancel')} )
          .then(async (dialog) => {

          const data = {
              action: 'PURGE_DATA_CACHE',
              data: VERSIONDATE,
          }
          try {
              await SPService.wbevent(API_URL.url, this.$store.state.user.currentUser.jwt, data);
          } catch (err) {

          }             
             await this.$store.dispatch('fleet/resetDataCache');
             window.localStorage.removeItem(storageKeyF);
             this.$store.dispatch("user/showAbout", false); 
             setTimeout(() => {
              debugger; window.location.reload();
             }, 1000)
          })
          .catch(function() {
            
          });


     
    },
    async clearUserCache() {
            this.$dialog
          .confirm(this.$t('This will purge all known users on this device and will force you to login again all users. There are no ways to go back. If you are not sure of what you are doing, please do not do it.'), { okText: this.$t('Purge'), cancelText: this.$t('Cancel')} )
          .then(async (dialog) => {
            const data = {
                action: 'PURGE_USER_CACHE',
                data: VERSIONDATE,
            }
            try {
              await SPService.wbevent(API_URL.url, this.$store.state.user.currentUser.jwt, data);
            } catch (err) {

            }
             window.localStorage.removeItem(storageKeyF);
             window.localStorage.removeItem(storageKeyU);
             try {
             await this.$store.dispatch('fleet/resetDataCache');
             await this.$store.dispatch('user/resetUserCache');
             } catch (err) {
                console.error(err);
             }
             window.localStorage.removeItem(storageKeyF);
             window.localStorage.removeItem(storageKeyU);
             this.$store.dispatch("user/showAbout", false); 
             setTimeout(() => {
               debugger; window.location.reload();
             }, 1000)
          })
          .catch(function() {
            
          });
    }
  },
  mounted() {
    this.showModal = false;
    this.$store.dispatch("user/showAbout", false);    

    if(this.$attrs && this.$attrs.purgeType) {     
      const self = this;   
      debugger
      if(this.$attrs.purgeType === 'user') {
            const data = {
                action: 'PURGE_USER_CACHE',
                data: VERSIONDATE,
            }
            try {
              SPService.wbevent(API_URL.url, this.$store.state.user.currentUser.jwt, data);
            } catch (err) {

            }             
             this.$store.dispatch('user/resetUserCache');
             window.localStorage.removeItem(storageKeyF);
             window.localStorage.removeItem(storageKeyU);
             
             this.$store.dispatch("user/showAbout", false); 
             this.$router.push("/app/kiosk");
             setTimeout(() => {
               debugger; window.location.reload();
             }, 1000)

      } else if (this.$attrs.purgeType === 'fleet' || this.$attrs.purgeType === 'data') {
          const data = {
                action: 'PURGE_DATA_CACHE',
                data: VERSIONDATE,
            }
            try {
                SPService.wbevent(API_URL.url, this.$store.state.user.currentUser.jwt, data);
            } catch (err) {

            }                                   
          this.$store.dispatch('fleet/resetDataCache');
          window.localStorage.removeItem(storageKeyF);
          this.$store.dispatch("user/showAbout", false); 
          this.$router.push("/app/kiosk");
          setTimeout(() => {
           debugger; window.location.reload();
          }, 1000)
      }
      else if (this.$attrs.purgeType === 'all') {
            const data = {
                action: 'PURGE_ALL_CACHE',
                data: VERSIONDATE,
            }
            try {
              SPService.wbevent(API_URL.url, this.$store.state.user.currentUser.jwt, data);
            } catch (err) {            
            }            
            window.localStorage.removeItem(storageKeyF);
             window.localStorage.removeItem(storageKeyU);
            this.$store.dispatch('fleet/resetDataCache');
            this.$store.dispatch('user/resetUserCache');
             window.localStorage.removeItem(storageKeyF);
             window.localStorage.removeItem(storageKeyU);
             this.$store.dispatch("user/showAbout", false); 
             this.$router.push("/app/kiosk");
             setTimeout(() => {
               debugger; window.location.reload();
             }, 1000)
      }
      else if (this.$attrs.purgeType === 'switchurl') {
            const data = {
                action: 'PURGE_ALL_CACHE',
                data: VERSIONDATE,
            }
            try {
              SPService.wbevent(API_URL.url, this.$store.state.user.currentUser.jwt, data);
            } catch (err) {            
            }            
            window.localStorage.removeItem(storageKeyF);
            window.localStorage.removeItem(storageKeyU);
            this.$store.dispatch('fleet/resetDataCache');
            this.$store.dispatch('user/resetUserCache');
            
            switchAPI(this.$attrs.url);
            debugger
             setTimeout(() => {
               debugger; window.location.reload();
             }, 1000)
      }      
    }
  },
  name: "ISALoginModal",
};
</script>

<style lang="scss" scoped>
</style>
