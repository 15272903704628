


export class IMGService {
  static logo = require('@/assets/images/logo.png');
  static logoMintt = require('@/assets/images/logo-mintt.png');
  static isalogo = require('@/assets/images/isalogo.png');
  static isalogonotxt = require('@/assets/images/isalogo_notext.png');
  static isalogonotxtdisconnected = require('@/assets/images/isalogo_notext_disconnected.png');
  static isalock = require('@/assets/images/loginLock.png');
  static takeCare = require('@/assets/images/states/take-care.png');
  static endAlert = require('@/assets/images/states/end-alert.png');
  static closed = require('@/assets/images/states/closed.png');
  static sensorOff = require('@/assets/images/states/sensor-off.png');
  static sensorOn = require('@/assets/images/states/sensor-on.png');
  static fall = require('@/assets/images/states/fall.png');
  static move = require('@/assets/images/states/move.png');
  static wakeUp = require('@/assets/images/states/wake-up.png');
  static sleep = require('@/assets/images/states/sleep.png');
  static autoTakeCare = require('@/assets/images/states/auto-take-care.png');
  static timeout = require('@/assets/images/states/timeout.png');
  static bgImage = window.isandroid ? "https://mintt.care/wp-content/uploads/2019/06/d%C3%A9tection-de-chutes-ISA-e1561108504976.jpg" : require("@/assets/images/photo-wide-4.jpg");
  static setup = require('@/assets/images/states/setup.png');
}
